<template>
  <div class="px-10">
    <v-row>
      <v-col cols="4">
        <div class="text-h4 primary--text py-3">ติดตามงานเเจ้งเตือนลูกค้า</div>
      </v-col>

      <v-col cols="4" class="text-left mt-5">
        <v-btn
          color="error"
          small
          outlined
          href="https://manual.permtang.com/documentation/setting/7-mail/"
          target="_blank"
          ><v-icon small right class="mr-2 mt-1">mdi-book-settings</v-icon>
          ดูการตั้งค่า จดหมายแจ้งเตือน
        </v-btn>
      </v-col>
      <v-col cols="4" class="text-left mt-5">
        <v-btn
          color="error"
          small
          outlined
          href="https://manual.permtang.com/documentation/setting/6-setup-sms/"
          target="_blank"
          ><v-icon small right class="mr-2 mt-1">mdi-book-settings</v-icon>
          ดูการตั้งค่า SMS
        </v-btn>
      </v-col>
    </v-row>
    <v-toolbar dense flat rounded outlined class="my-5 pt-3 pb-15">
      <div style="width: 180px">
        <v-select
          rounded
          label="ประเภทรถ"
          :items="car_type"
          v-model="carType"
          item-value="value"
          item-text="name"
          outlined
          dense
          hide-details
        />
        <!-- @change="onCategoryChange" -->
      </div>
      <div style="width: 180px">
        <v-select
          class="ml-1"
          rounded
          label="สินค้า"
          :items="product_type"
          v-model="productType"
          item-value="value"
          item-text="name"
          outlined
          dense
          hide-details
        ></v-select>
        <!-- @change="onProductChange" -->
      </div>
      <div style="width: 250px">
        <v-select
          class="mx-1"
          label="เลือกช่วงเวลา"
          v-model="timeRangeType"
          :items="timeRangeTypes"
          item-value="value"
          item-text="text"
          outlined
          rounded
          dense
          hide-details
        >
        </v-select>
      </div>

      <DatePicker
        v-if="timeRangeType == 3"
        label="วันที่เริ่ม"
        :value="filter.start_date"
        @onChange="
          (val) => {
            filter.start_date = val;
          }
        "
        hideDetails
        outlined
        rounded
        dense
      />

      <DatePicker
        v-if="timeRangeType == 3"
        label="ถึงวันที่"
        :value="filter.end_date"
        @onChange="
          (val) => {
            filter.end_date = val;
          }
        "
        hideDetails
        outlined
        rounded
        dense
      />

      <div>
        <v-checkbox
          class="ml-2 mt-5"
          label="เฉพาะที่มีที่อยู่"
          v-model="showOnlyAddress"
        >
        </v-checkbox>
      </div>
      <v-row justify="end">
        <v-btn
          color="success"
          small
          outlined
          class="mr-2 px-5"
          :loading="loading"
          :disabled="loading"
          @click="download_excel()"
          >ดาวน์โหลด <v-icon right small>mdi-download</v-icon>
        </v-btn>

        <PrintPostcard
          ref="printPostcard"
          :receivedData="selected.notificationOn"
          :branch="branch"
          v-if="!shouldRenderMailService"
        >
          <v-btn
            class="mr-2 px-5 float-right"
            small
            color="primary"
            outlined
          >
            พิมพ์ที่อยู่ Postcard<v-icon right>mdi-printer</v-icon>
          </v-btn>
        </PrintPostcard>

        <PrintMail
          ref="printMail"
          :receivedData="selected.notificationOn"
          :branch="branch"
        >
          <v-btn
            class="mr-2 px-5 float-right"
            small
            color="primary"
            outlined
            v-if="shouldRenderMailService"
          >
            พิมพ์จดหมาย <v-icon right>mdi-printer</v-icon>
          </v-btn>
        </PrintMail>

        <!-- <v-btn color="primary" small class="float-right" @click="getData()"
          >แสดงรายงาน <v-icon right>mdi-magnify</v-icon>
        </v-btn> -->
      </v-row>
    </v-toolbar>

    <v-card>
      <v-tabs
        v-model="tab"
        align-with-title
        background-color="white"
        active-class="light black--text"
      >
        <v-tabs-slider color="light"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <NotificationOn
            ref="notificationOn"
            :key="'NotificationOn_' + tab"
            @on-selected="selected.notificationOn = $event"
            @reset-checkbox="showOnlyAddress = false"
            :product-type="this.filter.product_type"
            :car-type="this.filter.car_type"
            :start-date="this.filter.start_date"
            :end-date="this.filter.end_date"
            :timeRangeType="this.timeRangeType"
            :showOnlyAddress="this.showOnlyAddress"
          />
        </v-tab-item>
        <v-tab-item>
          <NotificationOff
            ref="notificationOff"
            :key="'notificationOff_' + tab"
            @on-selected="selected.notificationOff = $event"
            :product-type="productType"
            :car-type="carType"
            :start-date="this.filter.start_date"
            :end-date="this.filter.end_date"
            :timeRangeType="this.timeRangeType"
          />
        </v-tab-item>
      </v-tabs-items>
      <!-- <div class="positionSMS">
        <p style="width: 200px" class="text-center mt-3 float-right">
          กดเพื่อส่ง SMS ทุกคันที่เลือก
        </p>
        <v-btn outlined small color="primary" class="mx-1 mt-2 float-right ">
          ส่ง sms
        </v-btn>
      </div> -->
    </v-card>
  </div>
</template>
<script>
import DatePicker from '@/components/DatePicker.vue';
import * as moment from 'moment';
import JsonExcel from 'vue-json-excel';
import NotificationOff from './NotificationOff';
import NotificationOn from './NotificationOn';
import { log } from 'util';
import PrintMail from '@/components/PrintDocument/printMail.vue';
import PrintPostcard from '@/components/PrintDocument/printPostcard.vue';

export default {
  components: {
    NotificationOff,
    NotificationOn,
    DatePicker,
    PrintMail,
    PrintPostcard,
  },
  data: () => ({
    showOnlyAddress: false,
    carType: 0,
    productType: 0,
    startDate: '',
    endDate: '',
    filter: {
      branch_id: '',
      item_per_page: 200,
      page: 1,
      car_type: 0,
      product_type: 0,
      call_remind_renewal: true,
      start_date: moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .format('YYYY-MM-DDTHH:mm:ss'),
      end_date: moment()
        .add(30, 'days')
        .set({ hour: 23, minute: 59, second: 59 })
        .format('YYYY-MM-DDTHH:mm:ss'),
    },
    loading: false,
    pageCount: 0,
    tab: 0,
    items: ['เตือนต่ออายุ', 'ไม่เตือน'],
    selected: {
      notificationOn: [],
      notificationOff: [],
    },
    headers: [
      { text: 'ลำดับ', value: 'index', align: 'center' },
      { text: 'ประเภทรถ', value: 'car_type', filterable: false },
      { text: 'สินค้า', value: 'product_type', filterable: false },
      { text: '', value: 'actions', sortable: false, filterable: false },
    ],
    list: [],
    dialogDetailUpdate: true,
    car_type_dict: {},
    car_type: [
      { value: 0, name: 'ทั้งหมด' },
      { value: 1, name: 'รถยนต์เท่านั้น' },
      { value: 2, name: 'รย.1' },
      { value: 3, name: 'รย.2' },
      { value: 4, name: 'รย.3' },
      { value: 5, name: 'รย.12' },
      { value: 6, name: 'อื่นๆ' },
    ],
    product_type_dict: [],
    product_type: [
      { value: 0, name: 'ทั้งหมด' },
      { value: 1, name: 'เฉพาะ ภาษี' },
      { value: 2, name: 'เฉพาะ พ.ร.บ.' },
      { value: 3, name: 'เฉพาะ ประกัน' },
      { value: 4, name: 'ภาษี + พ.ร.บ.' },
      { value: 5, name: 'พ.ร.บ. + ประกัน' },
    ],
    sub_category: [],
    timeRangeType: 0,
    timeRangeTypes: [
      { value: 0, text: 'วันนี้ + ล่วงหน้า 30 วัน' },
      { value: 1, text: 'วันนี้ + ล่วงหน้า 60 วัน' },
      { value: 2, text: 'วันนี้ + ล่วงหน้า 90 วัน' },
      { value: 3, text: 'เลือกช่วงวันที่' },
    ],
    branch: {
      name_for_receipt: '',
      location: '',
      phone_number: '',
      mail_setting: {
        mail_service: true,
        mail_title: '',
        show_khun: false,
        paragraph_1: '',
        paragraph_2: '',
        paragraph_3: '',
      },
    },
  }),
  created() {
    this.tab = this.$route.params;
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.$router.replace({ name: 'follow-alert-customer' }).catch(() => {});
    // this.getData();
    this.getBranchData();
  },
  methods: {
    // getData() {
    //   this.startDate = this.filter.start_date;
    //   this.endDate = this.filter.end_date;
    //   this.carType = this.filter.car_type;
    //   this.productType = this.filter.product_type;
    // },
    async download_excel() {
      this.loading = true;
      console.log('download_excel');
      console.log(this.filter);
      this.filter.call_remind_renewal = this.tab == 0 ? true : false;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/document/download_document_remind_renewal`,
        responseType: 'blob',
        data: body,
      })
        .then((response) => {
          if (
            moment(this.filter.start_date).format('DDMMYYYY') !=
            moment(this.filter.end_date).format('DDMMYYYY')
          ) {
            this.forceFileDownload(
              response,
              'ติดตามงานแจ้งเตือนลูกค้า' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '_' +
                moment(this.filter.end_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          } else {
            this.forceFileDownload(
              response,
              'ติดตามงานแจ้งเตือนลูกค้า' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          // this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
    checkSelected() {
      console.log(this.selected);
    },
    getBranchData() {
      let listFiltered = this.$store.state.list.branchs.filter((el) => {
        return el._id == this.$store.state.selected_branch._id;
      });
      this.branch.location = listFiltered[0].location;
      this.branch.mail_setting = listFiltered[0].mail_setting;
      this.branch.name_for_receipt = listFiltered[0].name_for_receipt;
      console.log(this.branch);
    },
  },
  watch: {
    carType: function (value) {
      this.filter.car_type = value;
    },
    productType: function (value) {
      this.filter.product_type = value;
    },
    timeRangeType: function (value) {
      if (value == 0) {
        // 0 = วันนี้ + 30วัน
        this.filter.start_date = moment().format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .add(+30, 'days')
          .format('YYYY-MM-DDTHH:mm:ss');
      } else if (value == 1) {
        // 1 = วันนี้ + 60วัน
        this.filter.start_date = moment().format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .add(+60, 'days')
          .format('YYYY-MM-DDTHH:mm:ss');
      } else if (value == 2) {
        // 2 = วันนี้ + 90วัน
        this.filter.start_date = moment().format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .add(+90, 'days')
          .format('YYYY-MM-DDTHH:mm:ss');
      } else if (value == 3) {
        // 3 = เลือกวันที่
        this.filter.start_date = moment().format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      }
    },
    tab: function () {
      this.carType = 0;
      this.productType = 0;
      this.timeRangeType = 0;
    },
  },
  computed: {
    shouldRenderMailService() {
      return (
        this.branch.mail_setting &&
        this.branch.mail_setting.mail_service !== false &&
        (this.branch.mail_setting.mail_service ||
          this.branch.mail_setting.mail_service === undefined)
      );
    },
  },
};
</script>
<style lang="scss">
.positionSMS {
  float: right;
  position: absolute;
  right: 60px;
  top: -0px;
}
</style>
