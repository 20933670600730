var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{key:_vm.selected.length,staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.loading,"loading-text":_vm.$table_loading_text,"page":_vm.page,"items-per-page":_vm.filter.item_per_page,"show-select":"","item-key":"_id","hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.customer_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ownership.customer_name)+" ")]}},{key:"item.car_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.car_type == 'CT1' ? 'รย.1' : '')+" "+_vm._s(item.car_type == 'CT2' ? 'รย.2' : '')+" "+_vm._s(item.car_type == 'CT3' ? 'รย.3' : '')+" "+_vm._s(item.car_type == 'CT4' ? 'รย.12' : '')+" "+_vm._s(item.car_type == 'CT13' ? 'รย.17' : '')+" "+_vm._s(item.car_type == 'CT5' ? 'รย.13' : '')+" "+_vm._s(item.car_type == 'CT14' ? 'รย.15' : '')+" "+_vm._s(item.car_type == 'CT6' ? 'ขส.10' : '')+" "+_vm._s(item.car_type == 'CT7' ? 'ขส.30' : '')+" "+_vm._s(item.car_type == 'CT8' ? 'ขส.40' : '')+" "+_vm._s(item.car_type == 'CT9' ? 'ขส.70' : '')+" "+_vm._s(item.car_type == 'CT10' ? 'ขส.80' : '')+" "+_vm._s(item.car_type == 'CT11' ? 'รย.1EV' : '')+" "+_vm._s(item.car_type == 'CT12' ? 'อื่น ๆ' : '')+" ")]}},{key:"item.customer_phone_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ownership.phone_number)+" ")]}},{key:"item.taxout_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.taxout_date))+" ")]}},{key:"item.3rd_insurance_enddate",fn:function(ref){
var item = ref.item;
return [(item["3rd_insurance_enddate"] == '')?_c('span'):_vm._e(),(item["3rd_insurance_enddate"] != '')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item["3rd_insurance_enddate"])))]):_vm._e()]}},{key:"item.insurance_enddate",fn:function(ref){
var item = ref.item;
return [(item.insurance_enddate == '')?_c('span'):_vm._e(),(item.insurance_enddate != '')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.insurance_enddate)))]):_vm._e()]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [(item.ownership.delivery_address.address == '')?_c('span',[_vm._v(_vm._s(item.ownership.location.address)+" "+_vm._s(item.ownership.location.sub_district)+" "+_vm._s(item.ownership.location.district)+" "+_vm._s(item.ownership.location.province)+" "+_vm._s(item.ownership.location.postal_code))]):_vm._e(),(item.ownership.delivery_address.address != '')?_c('span',[_vm._v(_vm._s(item.ownership.delivery_address.address)+" "+_vm._s(item.ownership.delivery_address.sub_district)+" "+_vm._s(item.ownership.delivery_address.district)+" "+_vm._s(item.ownership.delivery_address.province)+" "+_vm._s(item.ownership.delivery_address.postal_code))]):_vm._e()]}},{key:"footer",fn:function(){return [_c('table-pagination-custom',{attrs:{"page":_vm.filter.page,"itemsPerPage":_vm.filter.item_per_page,"length":_vm.pageCount},on:{"change-page":_vm.onChangePage,"change-items-per-page":_vm.onChangeitemsPerPage}})]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }