<template>
  <span>
    <span @click="print">
      <slot></slot>
    </span>
    <div style="display: none">
      <div id="printJS-form">
        <template v-for="(carIndex, car_index) in receivedData">
          <div :key="car_index">
            <div class="mail-header">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <b>ผู้ส่ง</b>
                        {{ branch.name_for_receipt }} โทร:
                        {{ $store.state.selected_branch.phone_number }}</br>
                        {{ address_branch }}
                      </p>
                      <p></p>
                    </td>
                  </tr>
                  <tr>
                    <td width="40%"></td>
                    <td>
                      <p class="f-24">
                        ผู้รับ
                        <span
                            v-if="branch.mail_setting.show_khun"
                          >
                            คุณ</span
                          >
                          {{
                            receivedData[car_index].ownership.customer_name
                          }}
                        {{ receivedData[car_index].ownership.phone_number }}
                      </p>
                      <p class="f-24">
                        {{ address_customer(car_index) }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mail-body">
              <p class="text-center mb-3">{{ today }}</p>
              <p>
                <b>เรื่อง</b>
                {{
                  branch.mail_setting.mail_title
                    ? branch.mail_setting.mail_title
                    : ''
                }}
              </p>
              <p>
                <b>เรียน</b>
                <span v-if="branch.mail_setting.show_khun"> คุณ</span>
                {{ receivedData[car_index].ownership.customer_name }}
              </p>
              <p class="p-indent" v-html="paragraph_1(car_index)"></p>
              <p class="p-indent" v-html="paragraph_2(car_index)"></p>
              <p class="p-indent" v-html="paragraph_3(car_index)"></p>
              <p class="text-right">จึงเรียนมาเพื่อทราบ</p>
            </div>

            <v-row>
              <v-col cols="1"></v-col>
              <v-col> </v-col>
            </v-row>
            <div
              v-if="car_index < receivedData.length - 1"
              class="pagebreak"
            ></div>
          </div>
        </template>
      </div>
    </div>
    <!-- <div>
            <button @click="print">Print PDF</button>
        </div> -->
  </span>
</template>

<script>
import printJS from 'print-js';
import * as moment from 'moment';

export default {
  props: ['receivedData','branch'],
  data() {
    return {
      today: this.formatDateTH(moment().format()),
    };
  },
  created() {
    
  },
  methods: {
    print() {
      printJS({
        printable: 'printJS-form',
        type: 'html',
        // type:'pdf',
        // header: 'PrintJS - Form Element Selection',
        font_size: '16px;',
        // font_size: '11px;',
        maxWidth: '80%',
        style: `
                    @page { size: A4 portrait; margin-top: 0; margin-left: 60px; margin-right: 60px; margin-bottom: 30px; }
                    .mail-header {margin-top: 2cm; width: 100%; height:7.9cm!important; border-bottom: 1px dotted #ccc!important;}
                    .mail-body {padding-top:1cm;}
                    .mb-3 {margin-bottom: 30px}
                    .fold-line {position: absolute; top:9.9cm; color:#363636;}
                    .f-20 { font-size: 20px!important; }
                    .f-24 { font-size: 24px!important; margin:0!important;}
                    .p-indent { margin-top: 0.5em; text-indent: 50px; margin-bottom: 20px;}
                    .text-center {text-align: center;}
                    .text-right {text-align: right;}
                    .pagebreak { page-break-before: always; }
                    p {margin: 0;}
                    `,
      });
    },
    
    carPlateValue(car_index) {
      const carPlates = this.receivedData[car_index].car_plate;
      return carPlates;
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      if (m_d === 'Invalid date') return '';
      return `${m_d}/${year + 543}`;
    },
    formatDateTH(date) {
      if (!date) {
        return '';
      }
      const year = parseInt(moment(date).format('YYYY'));
      const formattedDate = moment(date).format('D MMMM');
      const formattedDateWithYear = `${formattedDate} ${year + 543}`;
      return formattedDateWithYear;
    },
    formatParagraph(car_index, paragraph) {
      const taxout_date = this.receivedData[car_index].taxout_date
        ? this.formatDate(this.receivedData[car_index].taxout_date)
        : '-';
      const third_insurance_end_date = this.receivedData[car_index]
        .third_insurance_end_date
        ? this.formatDate(this.receivedData[car_index].third_insurance_end_date)
        : '-';
      const insurance_end_date = this.receivedData[car_index].insurance_end_date
        ? this.formatDate(this.receivedData[car_index].insurance_end_date)
        : '-';

      let formattedParagraph = paragraph
        .replace(
          '[ทะเบียนรถ]',
          `${this.receivedData[car_index].car_plate} ${this.receivedData[car_index].plate_province}`
        )
        .replace(/\n/g, '<br>')
        .replace('[วันภาษีขาด]', taxout_date)
        .replace('[วันพรบขาด]', third_insurance_end_date)
        .replace('[วันประกันขาด]', insurance_end_date);

      return formattedParagraph;
    },
    paragraph_1(car_index) {
      return this.formatParagraph(
        car_index,
        this.branch.mail_setting.paragraph_1
      );
    },
    paragraph_2(car_index) {
      return this.formatParagraph(
        car_index,
        this.branch.mail_setting.paragraph_2
      );
    },
    paragraph_3(car_index) {
      return this.formatParagraph(
        car_index,
        this.branch.mail_setting.paragraph_3
      );
    },
    address_customer(car_index) {
      let location = '';
      if (this.receivedData[car_index].ownership.delivery_address.address == '') {
        location = this.receivedData[car_index].ownership.location;
      } else {
        location = this.receivedData[car_index].ownership.delivery_address;
      }
      if (location.province == 'กรุงเทพมหานคร') {
        return (
          location.address +
          ' แขวง' +
          location.sub_district +
          ' เขต' +
          location.district +
          ' จ.' +
          location.province +
          ' ' +
          location.postal_code
        );
      } else {
        return (
          location.address +
          ' ต.' +
          location.sub_district +
          ' อ.' +
          location.district +
          ' จ.' +
          location.province +
          ' ' +
          location.postal_code
        );
      }
    },
  },
  computed: {
    address_branch() {
      let location = this.branch.location;
      if (location.province == 'กรุงเทพมหานคร') {
        return (
          location.address +
          ' แขวง' +
          location.sub_district +
          ' เขต' +
          location.district +
          ' จ.' +
          location.province +
          ' ' +
          location.postal_code
        );
      } else {
        return (
          location.address +
          ' ต.' +
          location.sub_district +
          ' อ.' +
          location.district +
          ' จ.' +
          location.province +
          ' ' +
          location.postal_code
        );
      }
    },
  },
};
</script>

<style>
</style>